<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
  <div class="filters-container pr mobile">
    <div class="switch-container">
      <span class="switch" @click="isUmanaIncluded = !isUmanaIncluded">
        <input v-model="isUmanaIncluded" type="checkbox" :checked="umanaIncluded">
        <div class="slider round" />
      </span>
      <span class="slider-text">Aggiungi categorie Umana</span>
    </div>
    <Multiselect
      v-show="isUmanaIncluded"
      v-model="filters.umanaCategories"
      :no-options-text="'Nessun risultato. Prova a modificare la ricerca.'"
      mode="multiple"
      class="search-active"
      :class="{invalidSelection: isUmanaIncluded && filters.umanaCategories.length === 0 && tryToGet}"
      :hide-selected="false"
      placeholder="Settori Aziendali Umana"
      :close-on-select="false"
      :searchable="false"
      label="category"
      value-prop="category"
      :groups="true"
      group-label="all"
      group-options="categories"
      :options="[{all: 'Seleziona tutto', categories: getUmanaCategories.filter(c => c.id.toLowerCase().startsWith(searchCategories.toLowerCase()))}]"
      @open="setFocus('searchInputCategories')"
      @close="searchCategories=''"
    >
      <template #open-indicator="{ }">
        <span v-show="filters.umanaCategories.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.umanaCategories = []}" />
        <span class="multiselect-caret" />
      </template>
      <template #multiplelabel="{values}">
        <div class="multi-label-container" style="display: flex;flex-wrap:wrap">
          <template v-for="(value, index) in values" :key="value.id">
            <div v-if="index < 1" class="multi-label">
              {{ value.id }}
            </div>
            <div v-if="index === 1" class="multi-label">
              + {{ filters.umanaCategories.length - 1 }} {{ filters.umanaCategories.length - 1 === 1 ? 'settore' : 'settori' }}
            </div>
          </template>
        </div>
      </template>
      <template #grouplabel="{ group, isSelected }">
        <div :class="getUmanaCategories.filter(c => c.id.toLowerCase().startsWith(searchCategories.toLowerCase())).length > 0 ? 'group-container hover-container': 'noDisplay'">
          <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
            Deseleziona tutto
          </button>
          <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
            Seleziona tutto
          </button>
        </div>
      </template>
         
      <template #option="{ option, isSelected }">
        <div class="option-container">
          <input type="checkbox" :checked="isSelected(option)">
          <div class="opt-label">
            {{ option.category }}
          </div>
        </div>
      </template>
      <template #beforelist="{}">
        <div class="search-before-list">
          <input
            ref="searchInputCategories"
            v-model="searchCategories"
            placeholder="Cerca per settore aziendale..."
            @keydown.stop=""
            @keyup.stop=""
          >
          <font-awesome-icon v-if="searchCategories!= ''" icon="xmark" class="close-icon-input" @click="searchCategories= ''" />
        </div>
      </template>
    </Multiselect>
  </div>
  <div v-show="isUmanaIncluded" class="filters-container pr mobile flex-start">
    <Multiselect
      v-model="filters.umanaJobCategories"
      :no-options-text="'Nessun risultato. Prova a modificare la ricerca.'"
      class="search-active"
      :class="{invalidSelection: isUmanaIncluded && filters.umanaJobCategories.length === 0 && tryToGet}"
      mode="multiple"
      :hide-selected="false"
      placeholder="Funzioni Aziendali Umana"
      :close-on-select="false"
      :searchable="false"
      label="category"
      value-prop="category"
      :groups="true"
      group-label="all"
      group-options="categories"
      :options="[{all: 'Seleziona tutto', categories: getJobCategories().filter(c => c.id.toLowerCase().startsWith(searchJob.toLowerCase()))}]"
      @open="setFocus('searchInputJob')"
      @close="searchJob=''"
    >
      <template #beforelist="{}">
        <div class="search-before-list">
          <input
            ref="searchInputJob"
            v-model="searchJob"
            placeholder="Cerca per funzione aziendale..."
            @keydown.stop=""
            @keyup.stop=""
          >
          <font-awesome-icon v-if="searchJob!= ''" icon="xmark" class="close-icon-input" @click="searchJob= ''" />
        </div>
      </template>
      <template #open-indicator="{ }">
        <span v-show="filters.umanaJobCategories.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.umanaJobCategories = []}" />
        <span class="multiselect-caret" />
      </template>

      <template #multiplelabel="{values}">
        <div class="multi-label-container" style="display: flex;flex-wrap:wrap">
          <template v-for="(value, index) in values" :key="value.id">
            <div v-if="index < 1" class="multi-label">
              {{ value.id }}
            </div>
            <div v-if="index === 1" class="multi-label">
              + {{ filters.umanaJobCategories.length - 1 }} {{ filters.umanaJobCategories.length - 1 === 1 ? 'funzione' : 'funzioni' }}
            </div>
          </template>
        </div>
      </template>
       
      <template #grouplabel="{ group, isSelected }">
        <div :class="getJobCategories().filter(c => c.id.toLowerCase().startsWith(searchCategories.toLowerCase())).length > 0 ? 'group-container hover-container': 'noDisplay'">
          <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
            Deseleziona tutto
          </button>
          <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
            Seleziona tutto
          </button>
        </div>
      </template>
  
      <template #option="{ option, isSelected }">
        <div class="option-container">
          <input type="checkbox" :checked="isSelected(option)">
          <div class="opt-label">
            {{ option.category }}
          </div>
        </div>
      </template>
    </Multiselect>
    <Multiselect
      v-model="filters.umanaRoles"
      mode="multiple"
      class="search-active"
      :class="{invalidSelection: isUmanaIncluded && filters.umanaRoles.length === 0 && tryToGet}"
      :hide-selected="false"
      placeholder="Ruoli Umana"
      :close-on-select="false"
      :searchable="false"
      label="category"
      value-prop="category"
      :groups="true"
      group-label="all"
      group-options="categories"
      :options="[{all: 'Seleziona tutto', categories: getUmanaRoles().filter(c => c.id.toLowerCase().startsWith(searchRoles.toLowerCase()))}]"
      :no-options-text="'Nessun risultato. Prova a modificare la ricerca.'"
      @open="setFocus('searchInputUmanaRoles')"
      @close="searchRoles=''"
    >
      <template #beforelist="{}">
        <div class="search-before-list">
          <input
            ref="searchInputUmanaRoles"
            v-model="searchRoles"
            placeholder="Cerca per ruolo..."
            @keydown.stop=""
            @keyup.stop=""
          >
          <font-awesome-icon v-if="searchRoles" icon="xmark" class="close-icon-input" @click="searchRoles=''" />
        </div>
      </template>
      <template #open-indicator="{ }">
        <span v-show="filters.umanaRoles.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.umanaRoles = []}" />
        <span class="multiselect-caret" />
      </template>
      <template #grouplabel="{ group, isSelected }">
        <div :class="getUmanaRoles().filter(c => c.id.toLowerCase().startsWith(searchRoles.toLowerCase())).length > 0 ? 'group-container hover-container': 'noDisplay'">
          <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
            Deseleziona tutto
          </button>
          <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
            Seleziona tutto
          </button>
        </div>
      </template>
  
      <template #option="{ option, isSelected }">
        <div class="option-container">
          <input type="checkbox" :checked="isSelected(option)">
          <div class="opt-label">
            {{ option.category }}
          </div>
        </div>
      </template>
      <template #multiplelabel="{values}">
        <div class="multi-label-container" style="display: flex;flex-wrap:wrap">
          <template v-for="(value, index) in values" :key="value.id">
            <div v-if="index < 1" class="multi-label">
              {{ value.id }}
            </div>
            <div v-if="index === 1" class="multi-label">
              + {{ filters.umanaRoles.length - 1 }} {{ filters.umanaRoles.length - 1 === 1 ? 'ruolo' : 'ruoli' }}
            </div>
          </template>
        </div>
      </template>
    </Multiselect>
  </div>
  <div v-show="isUmanaIncluded" class="filters-container pr mobile flex-start">
    <Multiselect
      v-model="filters.umanaSeniorities"
      mode="multiple"
      :hide-selected="false"
      placeholder="Livelli d'esperienza Umana"
      :close-on-select="false"
      class="search-active"
      :searchable="true"
      label="category"
      value-prop="category"
      :groups="true"
      group-label="all"
      group-options="categories"
      :options="[{ all: 'Seleziona tutto', categories: getUmanaSeniorities().filter(c => c.id.toLowerCase().startsWith(searchSen.toLowerCase()))}]"
      :no-options-text="'Nessun risultato. Prova a modificare la ricerca.'"
      @open="setFocus('searchInputSen')"
      @close="searchSen=''"
    >
      <template #beforelist="{}">
        <div class="search-before-list">
          <input
            ref="searchInputSen"
            v-model="searchSen"
            placeholder="Cerca per livello di esperienza..."
            @keydown.stop=""
            @keyup.stop=""
          >
          <font-awesome-icon v-if="searchSen!= ''" icon="xmark" class="close-icon-input" @click="searchSen= ''" />
        </div>
      </template>
      <template #open-indicator="{ }">
        <span v-show="filters.umanaSeniorities.length > 0" class="multiselect-clear-icon" style="margin:0 8px" @click="filters.umanaSeniorities = []" />
        <span class="multiselect-caret" />
      </template>

      <template #multiplelabel="{values}">
        <div class="multi-label-container" style="display: flex;flex-wrap:wrap">
          <template v-for="(value, index) in values" :key="value.id">
            <div v-if="index < 1" class="multi-label">
              {{ value.id }}
            </div>
            <div v-if="index === 1" class="multi-label">
              + {{ filters.umanaSeniorities.length - 1 }} {{ filters.umanaSeniorities.length - 1 === 1 ? 'livello' : 'livelli' }}
            </div>
          </template>
        </div>
      </template>
      <template #grouplabel="{ group, isSelected }">
        <div :class="getUmanaSeniorities().filter(c => c.id.toLowerCase().startsWith(searchCategories.toLowerCase())).length > 0 ? 'group-container hover-container': 'noDisplay'">
          <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
            Deseleziona tutto
          </button>
          <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
            Seleziona tutto
          </button>
        </div>
      </template>
         
      <template #option="{ option, isSelected }">
        <div class="option-container">
          <input type="checkbox" :checked="isSelected(option)">
          <div class="opt-label">
            {{ option.category }}
          </div>
        </div>
      </template>
    </Multiselect>
    <Multiselect
      v-model="filters.gender"
      class="genere"
      :no-options-text="'Nessun risultato. Prova a modificare la ricerca.'"
      mode="multiple"
      placeholder="Genere"
      :close-on-select="true"
      :searchable="true"
      :options="[{label: 'Donne', value: 'F'}, {label: 'Uomini', value: 'M'}]"
    >
      <template #multiplelabel="{values}">
        <div class="multi-label-container" style="display: flex;flex-wrap:wrap">
          <div v-for="value in values" :key="value" class="multi-label">
            <span>{{ value.label }}</span>
          </div>
        </div>
      </template>

      <template #option="{ option, isSelected }">
        <div class="option-container">
          <input type="checkbox" :checked="isSelected(option)">
          <div class="opt-label">
            {{ option.label }}
          </div>
        </div>
      </template>
      <template #caret="{ }">
        <span v-if="filters.gender.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="filters.gender = []" />
        <div class="infoText">
          <font-awesome-icon
            icon="info"
            class="fa-sm"
          />
        </div>
        <div class="infoTooltip">
          <span class="text"> Selezionando solo Donne o solo Uomini, estrarrai esclusivamente i candidati che hanno inserito il proprio CF in fase di candidatura.</span>
        </div>
        <span class="multiselect-caret" />
      </template>
    </Multiselect>
  </div>
</template>
<script>
import { getRegions } from '../province'
import { getIndustriesCategories } from '../umana'
import { getJobCategories } from '../umana'
import { getUmanaRoles } from '../umana'
import { getUmanaSeniorities } from '../umana'
import Multiselect from '@vueform/multiselect'
import { ref, nextTick } from 'vue'
import { computed } from '@vue/runtime-core'
export default {
  components: { Multiselect },
  props: {
    umanaIncluded: {
      type: Boolean,
      default: () => false
    },
    marketingFilters : {
      type: Object,
      default: () => {}
    },
    error : {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['update:included', 'update:filters', 'do:scrollToBottom'],
  setup (props, { emit }) {
    const searchInputJob = ref(null)
    const searchInputUmanaRoles = ref(null)
    const searchInputCategories = ref(null)
    const searchInputSen = ref(null)
    const searchJob = ref('')
    const searchCategories = ref('')
    const searchRoles = ref('')
    const searchSen = ref('')
    const regions = getRegions()
    const isUmanaIncluded = computed({
      get() {
        return props.umanaIncluded
      },
      set(value) {
        emit('update:included', value)
      }
    })
    const filters = computed({
      get() {
        return props.marketingFilters
      },
      set(value) {
        emit('update:filters', value)
      }
    })
    const tryToGet = computed({
      get() {
        return props.error
      }
    })
    const getUmanaCategories = computed(() => {
      let umana = []
      getIndustriesCategories().forEach(u => {
        umana.push ({
          category: u,
          id: u
        })
      })
      return umana
    })
    const setFocus = (input) => {
      nextTick(() => {
        if (input === 'searchInputSen') {
          if (searchInputSen.value) {
            searchInputSen.value.focus()
            emit('do:scrollToBottom')
          }
        }
        else if (input === 'searchInputJob') {
          if (searchInputJob.value) {
            searchInputJob.value.focus()
            emit('do:scrollToBottom')
          }
        }
        else if (input === 'searchInputCategories') {
          if (searchInputCategories.value) {
            searchInputCategories.value.focus()
          }
        }
        else if(input === 'searchInputUmanaRoles'){
          if (searchInputUmanaRoles.value) {
            searchInputUmanaRoles.value.focus()
            emit('do:scrollToBottom')
          }
        }
      })
    }

    return {
      tryToGet,
      filters,
      isUmanaIncluded,
      setFocus,
      searchInputUmanaRoles,
      searchJob,
      searchInputCategories,
      searchRoles,
      searchInputSen,
      searchInputJob,
      searchCategories,
      searchSen,
      regions,
      getUmanaCategories,
      getIndustriesCategories,
      getJobCategories,
      getUmanaRoles,
      getUmanaSeniorities
    }
  }
}
</script>
<style lang="scss">
.genere {
  .multiselect-clear {
    display: none;
  }
  .multiselect-clear-icon {
    transform: scale(1.15);
  }
  .infoTooltip {
     left: 20%!important;
    max-width: calc(100% - 70px)!important;
  }
 .infoText:hover + .infoTooltip {
  left: 0%!important;
    max-width: calc(100% - 70px)!important;
 }
}
</style>
