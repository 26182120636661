<template>
  <div class="marketing">
    <div class="marketing-header">
      <h4>Crea una lista in formato CSV con il <span class="highlight">nome</span>, il <span class="highlight">cognome</span> e l’<span class="highlight">email</span> dei candidati che rispettano i criteri dei filtri.</h4>
      <p style="font-weight:400">
        I candidati che estrarrai hanno dato il proprio consenso marketing.
      </p>
    </div>
    <div id="scrollFilters" class="scrollFilters">
      <div class="filters-container pr">
        <material-date-picker
          :date="filters.startDate"
          label="Data di inizio"
          :bordered="true"
          :required="false"
          :upper-limit="filters.endDate"
          @update:date="filters.startDate = $event; filters.startDate > filters.endDate ? filters.endDate = null : ''"
        />
        <material-date-picker
          :date="filters.endDate"
          label="Data di fine"
          :disabled="!filters.startDate"
          :bordered="true"
          :required="false"
          :lower-limit="filters.startDate"
          :upper-limit="new Date()"
          @update:date="filters.endDate = $event"
        />
      </div>
      <div class="filters-container pr mobile">
        <Multiselect
          v-model="filters.orp"
          placeholder="Filtra per"
          :close-on-select="true"
          :searchable="true"
          :can-clear="true"
          :options="[
            { value: 'all_candidates', label: 'Tutti i candidati' },
            { value: 'suggested_candidates', label: 'Candidati suggeriti' },
            { value: 'referral', label: 'Suggeritori' },
          ]"
        >
          <template #clear="">
            <div class="infoText">
              <font-awesome-icon
                icon="info"
                class="fa-sm"
              />
            </div>
            <div class="infoTooltip">
              <span v-if="filters.orp === 'all_candidates'" class="text"> Stai filtrando gli utenti CVing che hanno effttuato <b>almeno una candidatura</b> e che hanno accettato il <b>flag marketing</b>.</span>
              <span v-if="filters.orp === 'suggested_candidates'" class="text"> Stai filtrando gli utenti CVing che hanno effettuato <b>almeno una candidatura seguendo un suggerimento ORP</b> e che hanno accettato il flag <b>marketing</b>.</span>
              <span v-if="filters.orp === 'referral'" class="text"> Stai filtrando gli utenti CVing che hanno effettuato <b>almeno un suggerimento ORP</b> e che hanno accettato il <b>flag marketing</b>. I filtri che applichi si riferiscono alle <b>caratteristiche dei suggerimenti.</b></span>
            </div>
          </template>
        </Multiselect>
        <div
          :id="eligible && notEligible ? 'all' : !eligible && !notEligible ? 'none' : eligible ? 'eligible': 'notEligible'"
          :key="eligible && notEligible ? 'all' : !eligible && !notEligible ? 'none' : eligible ? 'eligible': 'notEligible'"
          class="eligible-custom"
        >
          <div class="elegibile-checkbox">
            <input type="checkbox" :checked="eligible" @change="eligible = !eligible"><label for="eligible">Candidati idonei</label>
          </div>
          <div class="elegibile-checkbox">
            <input type="checkbox" :checked="notEligible" @change="notEligible = !notEligible"> <label for="notEligible">Candidati non idonei</label>
          </div>
        </div>
      </div>
      <div class="filters-container pr mobile flex-start">
        <Multiselect
          v-model="filters.selectedProvinces"
          class="search-active"
          mode="multiple"
          placeholder="Inserisci provincia o regione"
          :close-on-select="false"
          :groups="true"
          :searchable="false"
          :options="filteredRegions"
          :hide-selected="false"
          clearable
          @open="setFocus('searchInput')"
          @close="searchProvince = ''"
        >
          <template #multiplelabel="{ values}">
            <div class="multiselect-multiple-label">
              <div v-if="values.length === regions.length" class="multi-label">
                <span>
                  Tutte le regioni selezionate
                </span>
              </div>
              <span v-else-if="thereAreRegions(values).length >0" style="display:flex">
                <div v-for="region in thereAreRegions(values)" :key="region" class="multi-label">
                  <span>{{ region }}</span>
                </div>
              </span>
              <div v-if="thereAreOccurence(values)" class="multi-label">
                {{ thereAreOccurence(values) }}
              </div>
            </div>
          </template>
          <template #grouplabel="{ group, isSelected }">
            <div class="group-container">
              <div class="group-label">
                {{ group.label }}
              </div>
              <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
                Deseleziona tutto
              </button>
              <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
                Seleziona tutto
              </button>
            </div>
          </template>
          <template #beforelist="{}">
            <div class="search-before-list">
              <input
                ref="searchInput"
                v-model="searchProvince"
                placeholder="Cerca per regione o provincia..."
                @keydown.stop=""
                @keyup.stop=""
              >
              <font-awesome-icon v-if="searchProvince!= ''" icon="xmark" class="close-icon-input" @click="searchProvince= ''" />
            </div>
          </template>
          <template #option="{ option, isSelected }">
            <div class="option-container">
              <input type="checkbox" :checked="isSelected(option)">
              <div class="opt-label">
                {{ option.label }}
              </div>
            </div>
          </template>
        </Multiselect>
    
        <div class="slider-custom">
          <label>
            Range d'età dei candidati
          </label>

          <div>
            <Slider
              v-model="filters.valueAge"
              style="width:100%;"
              :step="1"
              :show-tooltip="'always'"
              :tooltip-position="'bottom'"
              :min="18"
              :max="100"
              :format="(value) => { return Math.round(value) === 100 ? '+ 99 anni' : Math.round(value) + ' anni' }"
            />
          </div>
        </div>
      </div>
      
      <div class="filters-container pr mobile" style="margin-top:50px">
        <div class="switch-container">
          <span class="switch" @click="progettiIncluded = !progettiIncluded">
            <input v-model="progettiIncluded" type="checkbox" :checked="progettiIncluded">
            <div class="slider round" />
          </span>
          <span class="slider-text">Aggiungi progetti</span>
        </div>
        <v-select
          v-if="progettiIncluded"
          v-model="filters.projects"
          class="custom-multiselect"
          :class="{invalidSelection: filters.projects && filters.projects.length === 0 && progettiIncluded && tryToGet}"
          :options="searchResultsProjects"
          placeholder="Progetti"
          label="name"
          multiple
          :close-on-select="true"
          :deselect-from-dropdown="true"
          @search="getProjectsByName"
        >
          <template #open-indicator="{ }">
            <span v-show="filters.projects.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.projects = []}" />
            <span class="multiselect-caret" />
          </template>

          <template #no-options="{ search, searching, loading }">
            <template v-if="loading">
              <span style="opacity: 0.5; padding:1px 10px"> Ricerca per"<em>{{ search }}</em>".</span>
            </template>
            <template v-else-if="searching">
              <span style="opacity: 0.5; padding:1px 10px"> Nessun risultato per"<em>{{ search }}</em>".</span>
            </template>
            <em v-else style="opacity: 0.5; padding:1px 10px">Inizia a digitare...</em>
          </template>

          <template
            #selected-option-container="{ option , deselect}"
          >
            <div class="vs__selected" style="display:flex;align-items:center" @click="deselect(option)">
              <span>{{ option.name }}</span>
              <font-awesome-icon icon="xmark" class="close-icon-input" style="margin-left:4px;margin-top:1px" />
            </div>
          </template>
       
          <template #option="option">
            <input type="checkbox" :checked="filters.projects.map(e => e.id).indexOf(option.id) > -1">
            <span>
              {{ option.name }} 
            </span>
          </template>
        </v-select>
      </div>
      <umanaIncluded
        :umana-included="umanaIncluded"
        :marketing-filters="filters"
        :error="tryToGet"
        @update:included="umanaIncluded = $event"
        @update:filters="filters = $event"
        @do:scrollToBottom="scrollBottom()"
      />
      <div class="filters-container pr mobile">
        <div class="switch-container">
          <span class="switch" @click="companiesIncluded = !companiesIncluded">
            <input v-model="companiesIncluded" type="checkbox" :checked="companiesIncluded">
            <div class="slider round" />
          </span>
          <span class="slider-text">Aggiungi aziende o categorie CVing</span>
        </div>
        <v-select
          v-if="companiesIncluded"
          v-model="filters.companies"
          class="custom-multiselect"
          :options="searchResults"
          placeholder="Aziende"
          label="co_name"
          multiple
          :close-on-select="true"
          :deselect-from-dropdown="true"
          @search="getCompaniesByName"
        >
          <template #open-indicator="{ }">
            <span v-show="filters.companies.length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.companies = []}" />
            <span class="multiselect-caret" />
          </template>

          <template #no-options="{ search, searching, loading }">
            <template v-if="loading">
              <span style="opacity: 0.5; padding:1px 10px"> Ricerca per"<em>{{ search }}</em>".</span>
            </template>
            <template v-else-if="searching">
              <span style="opacity: 0.5; padding:1px 10px"> Nessun risultato per"<em>{{ search }}</em>".</span>
            </template>
            <em v-else style="opacity: 0.5; padding:1px 10px">Inizia a digitare...</em>
          </template>
          <template
            #selected-option-container="{ option , deselect}"
          >
            <div class="vs__selected" style="display:flex;align-items:center" @click="deselect(option)">
              <span>{{ option.co_name }}</span>
              <font-awesome-icon icon="xmark" class="close-icon-input" style="margin-left:4px;margin-top:1px" />
            </div>
          </template>
          <template #option="option">
            <input type="checkbox" :checked="filters.companies.map(e => e.coid).indexOf(option.coid) > -1">
            <span>
              {{ option.co_name }} 
            </span>
          </template>
        </v-select>
      </div>
      <template v-if="companiesIncluded">
        <div v-for="m, index in filters.macrocategories" :key="'macro_'+index + filters.macrocategories.length + allMacro.length" class="filters-container">
          <Multiselect
            :id="'macro_'+macro[index]"
            :key="'macro_'+macro[index]"
            v-model="macro[index]"
            :class="{invalidSelection: !macro && !macro[index] && tryToGet}"
            placeholder="Settori"
            :deselect-from-dropdown="true"
            :close-on-select="true"
            :searchable="true"
            label="it"
            value-prop="id"
            :options="allMacro"
            @select="changeMacro(index)"
            @clear="delesectMicro(index)"
            @open="() =>{scrollBottom()}"
          >
            <template #option="{ option }">
              <span :class="!option.active? 'disabled': 'active'">{{ option['it'] }}</span>
            </template>
          </Multiselect>
          <Multiselect
            :key="'micro__'+index"
            ref="microselect"
            v-model="filters.microcategories[index]"
            :class="{invalidSelection: filters.microcategories && filters.microcategories[index] && filters.microcategories[index].length === 0 && tryToGet}"
            :hide-selected="false"
            placeholder="Ruoli"
            mode="multiple"
            :disabled="!macro[index]"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :searchable="false"
            label="it"
            value-prop="id"
            :groups="true"
            group-label="all"
            group-options="micro"
            :options="getFilteredOptions(index)"
            style="margin-left:5px"
            @open="setFocus('searchInputRoles', index)"
            @close="searchRole = ''"
          >
            <template #open-indicator="{ }">
              <span v-show="filters.microcategories[index].length>0" class="multiselect-clear-icon" style="margin:0 8px" @click="()=>{filters.microcategories[index] = []}" />
              <span class="multiselect-caret" />
            </template>

            <template #multiplelabel="">
              <div class="multi-label-container">
                <div class="multi-label">
                  <span v-if="macro[index] && allMicro.filter(micro => micro['id_parent'] === macro[index]).length == filters.microcategories[index].length">
                    Selezionati tutti i ruoli
                  </span>
                  <span v-else-if="filters.microcategories[index].length === 1">
                    {{ filters.microcategories[index].length }} ruolo selezionato
                  </span>
                  <span v-else>
                    {{ filters.microcategories[index].length }} ruoli selezionati
                  </span>
                </div>
              </div>
            </template>
            <template #grouplabel="{ group, isSelected }">
              <div class="group-container">
                <div class="group-label">
                  {{ group.label }}
                </div>
                <button v-if="isSelected(group)" class="deselect-all-btn" @click="selectAllOptions(group.options, false)">
                  Deseleziona tutto
                </button>
                <button v-else class="select-all-btn" @click="selectAllOptions(group.options, true)">
                  Seleziona tutto
                </button>
              </div>
            </template>
         
            <template #beforelist="{}">
              <div class="search-before-list">
                <input
                  ref="searchInputRoles"
                  v-model="searchRole"
                  placeholder="Cerca il ruolo..."
                  @keydown.stop=""
                  @keyup.stop=""
                >
                <font-awesome-icon v-if="searchRole != ''" icon="xmark" class="close-icon-input" @click="searchRole = ''" />
              </div>
            </template>
            <template #option="{ option, isSelected }">
              <div class="option-container">
                <input type="checkbox" :checked="isSelected(option)">
                <div class="opt-label">
                  {{ option['it'] }}
                </div>
                <div v-if="false" class="selection" :class="{'selected': isSelected(option)}">
                  Selezionato!
                </div>
              </div>
            </template>
          </Multiselect>
          <font-awesome-icon
            v-if="(index + 1 != filters.macrocategories.length && filters.macrocategories.length>1) || (filters.macrocategories.length === allMacro.length)"
            class="user-icon"
            icon="trash-can"
            @click="removeMacro(index)"
          />

          <font-awesome-icon
            v-else
            class="user-icon"
            icon="plus"
            :class="{'user-icon-disabled': (macro && macro[index] === '') || (filters.microcategories[index] && filters.microcategories[index].length === 0)}"
            @click="addMacro(macro[index], macro[index] === '' || filters.microcategories[index].length === 0)"
          >
            +
          </font-awesome-icon>
        </div>
      </template>
    </div>
    <div>
      <div style="text-align: right; margin-right:48px; margin-top:20px ;">
        <material-button text="Reset" class="reset" @click="resetFilters()" />
     
        <material-button text="Crea CSV" type="info" @click="getCount()" />
      </div>
    </div>
    <div v-if="showCounter" class="marketing-modal">
      <div class="modal-content">
        <span class="close-icon" @click="showCounter = false">
          <font-awesome-icon icon="xmark" class="close" />
        </span>
        <h1 class="modal-title prew">
          Preview d'estrazione dati
        </h1>
        <div class="img-wrapper">
          <div class="rounded-full lente" />
        </div>
        
        <h2 class="modal-subtitle">
          Le selezioni produrranno un file CSV contenente nome, cognome ed e-mail di
          <p v-if="filters.orp === 'all_candidates'">
            {{ count }} {{ count === 1 ? 'candidato' : 'candidati' }}
          </p>
          <p v-else-if="filters.orp === 'suggested_candidates'">
            {{ count }} {{ count === 1 ? 'candidato suggerito' : 'candidati suggeriti' }}
          </p>
          <p v-else-if="filters.orp === 'referral'">
            {{ count }} {{ count === 1 ? 'suggeritore' : 'suggeritori' }}
          </p>
        </h2>
        <button class="continue-button" @click="getReport()">
          Continua ed effettua il download
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import { getRegions } from '../province'
import umanaIncluded from './UmanaIncluded.vue'
import Slider from '@vueform/slider'
import Multiselect from '@vueform/multiselect'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, nextTick } from 'vue'
import { reactive } from '@vue/reactivity'
import { onBeforeMount, watch, computed, inject } from '@vue/runtime-core'
import { apicross, sbapibackoffice, reportbackoffice } from '@/api'
import dayjs from 'dayjs'
import { spinnerStore } from '@/stores/spinner'
require('dayjs/locale/it')
dayjs.locale('it')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  components: { umanaIncluded, Multiselect, Slider },
  setup () {
    const searchResults= ref([])
    const searchResultsProjects = ref([])
    const toast = inject('$toast')
    const tryToGet = ref(false)
    const microselect = ref(null)
    const macroCount = ref(1)
    const macro = ref([])
    const allMacro = ref([])
    const allMicro = ref([]) 
    const filters = reactive({
      locations: [],
      selectedProvinces: [],
      orp: 'all_candidates',
      umanaCategories:[],
      umanaJobCategories:[],
      umanaRoles: [],
      umanaSeniorities: [],
      gender: [],
      macrocategories: [[]],
      microcategories: [[]],
      projects: [],
      companies: [],
      startDate: new Date('2018-01-01'),
      endDate: new Date(),
      valueAge: [18, 100]
    })
    const searchInput = ref(null)
    const searchInputRoles = ref(null)
    const searchProvince = ref('')
    const searchRole = ref('')
    const regions = getRegions()
    const count = ref(0)
    const umanaIncluded = ref(false)
    const progettiIncluded = ref(false)
    const companiesIncluded = ref(true)
    const eligible = ref(true)
    const notEligible = ref(true)
    const objToSend = ref({})
    const showCounter = ref(false)
    const spinner = spinnerStore()
    const showRecap = ref(true)
    watch(
      [progettiIncluded, umanaIncluded, companiesIncluded],
      ([newProgettiIncluded, newUmanaIncluded, newCompaniesIncluded], [oldProgettiIncluded, oldUmanaIncluded, oldCompaniesIncluded]) => {
        if (!newProgettiIncluded && !newUmanaIncluded && !newCompaniesIncluded) {
          if(oldUmanaIncluded){
            umanaIncluded.value = true
          }
          if(oldProgettiIncluded){
            progettiIncluded.value = true
          }
          if(oldCompaniesIncluded){
            companiesIncluded.value = true
          }
          toast.warning('È indispensabile includere almeno un\'azienda nella selezione: attivare almeno uno dei tre switch.')
        }
        if(newUmanaIncluded) {
          scrollBottom()
        }
      },
      { deep: true }
    )
    watch(
      () => filters.macrocategories,
      () => {
        allMacro.value.forEach(macro=>{
          if(filters.macrocategories.includes(macro.id)) {
            macro.active = false
          } else {
            macro.active = true
          }
        })

      },
      { deep: true }
    )
    watch(
      [eligible, notEligible],
      ([newEligible, newNotEligible], [oldEligible, oldNotEligible]) => {
        if (!newEligible && !newNotEligible) {
          if (oldEligible) {
            nextTick(() => {
              console.log('eligible', true)
              eligible.value = true
            })
          }
          if (oldNotEligible) {
            nextTick(() => {
              console.log('not eligible', true)
              notEligible.value = true
            })
          }
          toast.warning('Seleziona almeno una opzione tra candidati "Idonei" e "Non idonei"')
        }
      },
      { deep: true }
    )
    onBeforeMount(() => {
      let lang = 'it'
      Promise.all([
        apicross.get('/jobsmacrocategories?language=' + lang),
        apicross.get('/jobsmicrocategories?language=' + lang + '&idParent=0')
      ]).then(([macro, micro]) =>{
        allMacro.value = macro.data
        allMicro.value = micro.data
      })
    })
    const changeMacro = (index) =>{
      tryToGet.value = false
      filters.macrocategories[index] = macro.value[index]
      filters.microcategories[index] = []
      setTimeout(()=>{
        // il ref piu' recente ( aggiunto per ultimo) ha sempre value 0
        microselect.value[0].selectAll()
      }, 200)
    }
    const delesectMicro = (index) =>{
      tryToGet.value = false
      filters.macrocategories[index] = []
      filters.microcategories[index] = []
    }
    const formattedCategories = computed(() => {
      const lang = 'it'
      const formatted = []
      allMacro.value.forEach((macro) => {
        const micro = allMicro.value.filter(m=> m.id_parent === macro.id)
        formatted.push({
          label: macro[lang],
          macrocategoryId: macro.id,
          options: micro
        })
      })
      console.log(formatted)
      return formatted 
    })
    const addMacro = (macro, bool) => {
      tryToGet.value = false
      if(!macro){
        toast.error('Inserisci prima il settore')
      } else{
        if(!bool){
          filters.macrocategories.push([])
          filters.microcategories.push([])
          setTimeout(()=> {
            scrollBottom()
          }, 250)
        } else {
          const f = allMacro.value.find(r => r.id === macro)
          toast.error('Inserisci almeno un ruolo per il settore ' + f['it'])
        }

      }

    }
    const scrollBottom = () => {
      const element = document.getElementById('scrollFilters')
      const scrollOffset = element.scrollHeight - element.clientHeight
      element.style.scrollBehavior = 'smooth'
      element.scrollTop = scrollOffset > element.scrollTop ? scrollOffset : element.scrollTop
    }
    const removeMacro = (index) =>{
      tryToGet.value = false
      macro.value.splice(index, 1)
      filters.macrocategories.splice(index, 1)
      filters.microcategories.splice(index, 1)
    }
    const selectAllRoles = (index)=>{
      tryToGet.value = false
      if(filters.microcategories[index].length === allMicro.value.filter(m => m.id_parent === macro.value[index]).map(m=> m.id).length){
        filters.microcategories[index] = []
      
      } else {
        filters.microcategories[index] = []
        filters.microcategories[index] = allMicro.value.filter(m => m.id_parent === macro.value[index]).map(m=> m.id)
       
      }
    }
    const getCompaniesByName = async (letter, loading) => {
      if (letter) {
        loading(true)
        let results = await sbapibackoffice.get('companieslist?initialLettersOfCompanies=' + letter)
        results.data = results.data.filter(campaign => campaign.coid !== isUmana.value)

        searchResults.value = [...new Set([...results.data, ...filters.companies])].sort((a, b) => a.co_name.localeCompare(b.co_name))
      } else {
        searchResults.value = filters.companies.sort((a, b) => a.co_name.localeCompare(b.co_name))
      }
  
      if (searchResults.value.length > 0) {
        scrollBottom()
      }
  
      loading(false)
    }
    const getProjectsByName = async (letter, loading) => {
      searchResultsProjects.value = []

      if (letter) {
        loading(true)
        let results = await sbapibackoffice.get('/projects/search?name=' + letter +'&published=true')
        searchResultsProjects.value = results.data.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        searchResultsProjects.value = []
      }

      if (filters.projects && filters.projects.length > 0) {
        searchResultsProjects.value = [...searchResultsProjects.value, ...filters.projects.filter(r => !searchResultsProjects.value.find(e => e.id === r.id))]
      }

      loading(false)
    }
   
    const getCount = async () => {
      spinner.show()
      objToSend.value = {}
      tryToGet.value = true
      let continueToGetReport = true
      if(!filters.orp) {
        continueToGetReport = false
        toast.warning('Seleziona almeno un\'opzione tra "Tutti i candidati", "Candidati suggeriti" e  "Suggeritori"')
      } 
      if(!eligible.value && !notEligible.value) {
        continueToGetReport = false
        toast.warning('Seleziona almeno un\'opzione tra candidati "Idonei" e "Non idonei"')
      } 
      if(umanaIncluded.value && filters.umanaCategories.length === 0 && filters.umanaJobCategories.length === 0 && filters.umanaRoles.length === 0) {
        continueToGetReport = false
        if(filters.umanaCategories.length === 0){
          toast.error('Attenzione: Seleziona almeno un settore aziendale o rimuovi l\'inclusione di "Umana".')
        } else if(filters.umanaJobCategories.length === 0){
          toast.error('Attenzione: Seleziona almeno una funzione aziendale o rimuovi l\'inclusione di "Umana".')
     
        } else {
          toast.error('Attenzione: Seleziona almeno un ruolo o rimuovi l\'inclusione di "Umana".')
      
        }
      } 
      if(progettiIncluded.value && filters.projects.length === 0) {
        continueToGetReport = false
        toast.error('Attenzione: Seleziona almeno un progetto o rimuovi l\'inclusione di "Progetti".')
      }

      if (!(filters.macrocategories.length === 1 && filters.macrocategories[0].length === 0)) {
        for (let i = 0; i < filters.macrocategories.length; i++) {
          if (Array.isArray(filters.macrocategories[i]) && filters.macrocategories[i].length === 0) {
            continueToGetReport = false
            toast.error('Seleziona il settore ed almeno un ruolo')
            break
          }
          if (filters.microcategories[i].length === 0) {
            continueToGetReport = false
            toast.error('Seleziona almeno un ruolo per ogni settore')
            break
          }
        }
      }

      if (continueToGetReport) {
        tryToGet.value = false
        let all = filters.microcategories.flat()
        let ids = companiesIncluded.value ? filters.companies.map(c => c.coid) : [-1]
        let projectsIds = progettiIncluded.value ? filters.projects.map(p => p.id) : [-1]
        if (umanaIncluded.value) {
          ids.push(isUmana.value)
        }
        objToSend.value = {
          reportType: filters.orp,
          idProjects: projectsIds && projectsIds.length > 0 ? projectsIds : [-1],
          companyIds: ids && ids.length > 0 ? ids : [],
          microcategoryIds: all,
          fromDate: dayjs.utc(filters.startDate).local().format('YYYY-MM-DD'),
          toDate: dayjs.utc(filters.endDate).local().format('YYYY-MM-DD'),
          ageMin:filters.valueAge[0] === 18 && filters.valueAge[1] === 100 ? -1 : filters.valueAge[0],
          ageMax:filters.valueAge[0] === 18 && filters.valueAge[1] === 100 ? -1 : filters.valueAge[1],
          province: filters.selectedProvinces,
          umanaIncluded: umanaIncluded.value,
          umanaCategory1: umanaIncluded.value ? filters.umanaCategories : [],
          umanaCategory2: umanaIncluded.value ? filters.umanaRoles : [],
          umanaCategory3: umanaIncluded.value ? filters.umanaJobCategories : [],
          umanaSeniority: umanaIncluded.value ? filters.umanaSeniorities : [],
          gender: umanaIncluded.value ? filters.gender : [],
          suitableStatus: eligible.value && notEligible.value ? 0 : eligible.value ? 1 : 2
         
        }
        try {
          const response = await reportbackoffice.post('/marketing/csv-preview-counter', objToSend.value)
          count.value = response.data
          showCounter.value = true
        } catch (err) {
          toast.clear()
          toast.error('Impossibile effettuare il count dei records')
        } finally {
          spinner.hide()
        }
      }

      spinner.hide()
    }
    const getReport = () => {
      showCounter.value = false
      toast.warning(' Creazione del report in corso...<p> L\'operazione potrebbe richiedere qualche secondo, attendi per favore</p>')
      spinner.show()
      reportbackoffice
        .post('/marketing/csv', objToSend.value)
        .then((response) => {
          toast.clear()
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'marketing-report__'+dayjs(new Date()).local().format('DD-MM-YY') +'.csv'
          link.click()
          toast.success('Download pronto!')
          
        })
        .catch((err) => {
          toast.clear()
          toast.error('Impossibile effettuare il download: ', err.toString())
        })
        .finally(()=>{
          count.value = 0
          objToSend.value = {}
          tryToGet.value = false
          spinner.hide()
        }) 
    }
    const resetFilters = () => {
      spinner.show()
      macro.value = []
      filters.orp = 'all_candidates',
      filters.selectedProvinces = [],
      filters.umanaCategories = [],
      filters.umanaCategories = [],
      filters.umanaJobCategories = [],
      filters.umanaRoles= [],
      filters.umanaSeniorities=[],
      filters.gender= [],
      filters.macrocategories= [[]],
      filters.microcategories= [[]],
      filters.projects =[],
      filters.valueAge = [ 18, 100]
      filters.companies= [],
      filters.startDate= new Date('2018-01-01'),
      filters.endDate= new Date()
      filters.projects = [],
      progettiIncluded.value = false
      umanaIncluded.value = false
      companiesIncluded.value = true
      spinner.hide()
    }
    const isUmana = computed(() => {
      let umanaCoid = 454
      switch (process.env.VUE_APP_BUILD_ENV) {
        case 'development':
          umanaCoid = 257
          break
        case 'staging':
          umanaCoid = 271
          break
        case 'production':
          umanaCoid = 454 
          break
      }
      return umanaCoid
    })
    const thereAreRegions = (values) => {
      const occorrenzeRegioni = values.reduce((acc, item) => {
        acc[item.region] = (acc[item.region] || 0) + 1
        return acc
      }, {})

      const res = Object.entries(occorrenzeRegioni).map(([label, occorrenze]) => ({
        label,
        occorrenze,
        complete: occorrenze === regions.find(i => i.label === label).numberOfProvinces
      }))

      const obj = res.reduce((acc, item) => {
        if (item.complete) {
          if (acc.complete.length < 2) {
            acc.complete.push(item.label)
          } else {
            acc.occorrenze += item.occorrenze
          }
        } else {
          acc.occorrenze += item.occorrenze
        }
        return acc
      }, {
        complete: [],
        occorrenze: 0
      })
      return obj.complete
    }
    const thereAreOccurence = (values) => {
      const occorrenzeRegioni = values.reduce((acc, item) => {
        acc[item.region] = (acc[item.region] || 0) + 1
        return acc
      }, {})

      const res = Object.entries(occorrenzeRegioni).map(([label, occorrenze]) => ({
        label,
        occorrenze,
        complete: occorrenze === regions.find(i => i.label === label).numberOfProvinces
      }))

      const obj = res.reduce((acc, item) => {
        if (item.complete) {
          if (acc.complete.length < 2) {
            acc.complete.push(item.label)
          } else {
            acc.occorrenze += item.occorrenze
          }
        } else {
          acc.occorrenze += item.occorrenze
        }
        return acc
      }, {
        complete: [],
        occorrenze: 0
      })
      if (obj.occorrenze > 0) {
        const provinceText = obj.occorrenze === 1 ? 'provincia' : 'province'
        return`+ ${obj.occorrenze} ${provinceText}`
      }

    }
    const filteredRegions = computed(() => {
      if (searchProvince.value === '') {
        return regions
      }

      const filteredOptions = regions.flatMap(region => {
        const filteredOptions = region.options.filter(option =>
          option.label.toLowerCase().includes(searchProvince.value.toLowerCase()) ||
          option.region.toLowerCase().includes(searchProvince.value.toLowerCase()) ||
          option.value.toLowerCase().includes(searchProvince.value.toLowerCase())
        )

        if (filteredOptions.length > 0) {
          return {
            ...region,
            options: filteredOptions
          }
        }

        return []
      })

      return filteredOptions
    })
    // Imposta il focus sull'input di ricerca all'apertura della Multiselect
    const setFocus = (input, index) => {
      nextTick(() => {
        if (input === 'searchInputRoles') {
          if (searchInputRoles.value && searchInputRoles.value[index]) {
            scrollBottom()
            searchInputRoles.value[0].focus()
          }
        } 
        else {
          if (searchInput.value) {
            searchInput.value.focus()
          }
        }
      })
    }

    const getFilteredOptions = (index) => {
      return [
        {
          all: 'Seleziona tutto',
          micro: allMicro.value.filter(
            (micro) =>
              micro['id_parent'] === macro.value[index] &&
              micro['it'].toLowerCase().includes(searchRole.value.toLowerCase())
          )
        }
      ]
    }

    return {
      regions,
      searchProvince,
      searchRole,
      searchResultsProjects,
      getProjectsByName,
      thereAreRegions,
      thereAreOccurence,
      scrollBottom,
      getFilteredOptions,
      setFocus,
      searchInput,
      searchInputRoles,
      filteredRegions,
      progettiIncluded,
      resetFilters,
      count,
      getReport,
      companiesIncluded,
      umanaIncluded,
      showCounter,
      tryToGet,
      isUmana,
      formattedCategories,
      getCount,
      showRecap,
      searchResults,
      getCompaniesByName,
      selectAllRoles,
      delesectMicro,
      microselect,
      filters,
      addMacro,
      macro,
      macroCount,
      removeMacro,
      changeMacro,
      allMacro,
      allMicro,
      eligible,
      notEligible
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss">
@use"../assets/scss/marketing";
.v3dp__datepicker .v3dp__popout {
  z-index: 999!important;
}
</style>
