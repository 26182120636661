const regions = [
  {
    label: 'Abruzzo',
    numberOfProvinces: 4,
    options: [
      { label: 'Chieti', value: 'CH', region: 'Abruzzo' },
      { label: 'L\'Aquila', value: 'AQ', region: 'Abruzzo' },
      { label: 'Pescara', value: 'PE', region: 'Abruzzo' },
      { label: 'Teramo', value: 'TE', region: 'Abruzzo' }
    ]
  },
  {
    label: 'Basilicata',
    numberOfProvinces: 2,
    options: [
      { label: 'Matera', value: 'MT', region: 'Basilicata' },
      { label: 'Potenza', value: 'PZ', region: 'Basilicata' }
    ]
  },
  {
    label: 'Calabria',
    numberOfProvinces: 5,
    options: [
      { label: 'Catanzaro', value: 'CZ', region: 'Calabria' },
      { label: 'Cosenza', value: 'CS', region: 'Calabria' },
      { label: 'Crotone', value: 'KR', region: 'Calabria' },
      { label: 'Reggio Calabria', value: 'RC', region: 'Calabria' },
      { label: 'Vibo Valentia', value: 'VV', region: 'Calabria' }
    ]
  },
  {
    label: 'Campania',
    numberOfProvinces: 5,
    options: [
      { label: 'Avellino', value: 'AV', region: 'Campania' },
      { label: 'Benevento', value: 'BN', region: 'Campania' },
      { label: 'Caserta', value: 'CE', region: 'Campania' },
      { label: 'Napoli', value: 'NA', region: 'Campania' },
      { label: 'Salerno', value: 'SA', region: 'Campania' }
    ]
  },
  {
    label: 'Emilia-Romagna',
    numberOfProvinces: 9,
    options: [
      { label: 'Bologna', value: 'BO', region: 'Emilia-Romagna' },
      { label: 'Ferrara', value: 'FE', region: 'Emilia-Romagna' },
      { label: 'Forlì-Cesena', value: 'FC', region: 'Emilia-Romagna' },
      { label: 'Modena', value: 'MO', region: 'Emilia-Romagna' },
      { label: 'Parma', value: 'PR', region: 'Emilia-Romagna' },
      { label: 'Piacenza', value: 'PC', region: 'Emilia-Romagna' },
      { label: 'Ravenna', value: 'RA', region: 'Emilia-Romagna' },
      { label: 'Reggio Emilia', value: 'RE', region: 'Emilia-Romagna' },
      { label: 'Rimini', value: 'RN', region: 'Emilia-Romagna' }
    ]
  },
  {
    label: 'Friuli-Venezia Giulia',
    numberOfProvinces: 4,
    options: [
      { label: 'Gorizia', value: 'GO', region: 'Friuli-Venezia Giulia' },
      { label: 'Pordenone', value: 'PN', region: 'Friuli-Venezia Giulia' },
      { label: 'Trieste', value: 'TS', region: 'Friuli-Venezia Giulia' },
      { label: 'Udine', value: 'UD', region: 'Friuli-Venezia Giulia' }
    ]
  },
  {
    label: 'Lazio',
    numberOfProvinces: 5,
    options: [
      { label: 'Frosinone', value: 'FR', region: 'Lazio' },
      { label: 'Latina', value: 'LT', region: 'Lazio' },
      { label: 'Rieti', value: 'RI', region: 'Lazio' },
      { label: 'Roma', value: 'RM', region: 'Lazio' },
      { label: 'Viterbo', value: 'VT', region: 'Lazio' }
    ]
  },
  {
    label: 'Liguria',
    numberOfProvinces: 4,
    options: [
      { label: 'Genova', value: 'GE', region: 'Liguria' },
      { label: 'Imperia', value: 'IM', region: 'Liguria' },
      { label: 'La Spezia', value: 'SP', region: 'Liguria' },
      { label: 'Savona', value: 'SV', region: 'Liguria' }
    ]
  },
  {
    label: 'Lombardia',
    numberOfProvinces: 12,
    options: [
      { label: 'Bergamo', value: 'BG', region: 'Lombardia' },
      { label: 'Brescia', value: 'BS', region: 'Lombardia' },
      { label: 'Como', value: 'CO', region: 'Lombardia' },
      { label: 'Cremona', value: 'CR', region: 'Lombardia' },
      { label: 'Lecco', value: 'LC', region: 'Lombardia' },
      { label: 'Lodi', value: 'LO', region: 'Lombardia' },
      { label: 'Mantova', value: 'MN', region: 'Lombardia' },
      { label: 'Milano', value: 'MI', region: 'Lombardia' },
      { label: 'Monza e Brianza', value: 'MB', region: 'Lombardia' },
      { label: 'Pavia', value: 'PV', region: 'Lombardia' },
      { label: 'Sondrio', value: 'SO', region: 'Lombardia' },
      { label: 'Varese', value: 'VA', region: 'Lombardia' }
    ]
  },
  {
    label: 'Marche',
    numberOfProvinces: 5,
    options: [
      { label: 'Ancona', value: 'AN', region: 'Marche' },
      { label: 'Ascoli Piceno', value: 'AP', region: 'Marche' },
      { label: 'Fermo', value: 'FM', region: 'Marche' },
      { label: 'Macerata', value: 'MC', region: 'Marche' },
      { label: 'Pesaro e Urbino', value: 'PU', region: 'Marche' }
    ]
  },
  {
    label: 'Molise',
    numberOfProvinces: 2,
    options: [
      { label: 'Campobasso', value: 'CB', region: 'Molise' },
      { label: 'Isernia', value: 'IS', region: 'Molise' }
    ]
  },
  {
    label: 'Piemonte',
    numberOfProvinces: 8,
    options: [
      { label: 'Alessandria', value: 'AL', region: 'Piemonte' },
      { label: 'Asti', value: 'AT', region: 'Piemonte' },
      { label: 'Biella', value: 'BI', region: 'Piemonte' },
      { label: 'Cuneo', value: 'CN', region: 'Piemonte' },
      { label: 'Novara', value: 'NO', region: 'Piemonte' },
      { label: 'Torino', value: 'TO', region: 'Piemonte' },
      { label: 'Verbano-Cusio-Ossola', value: 'VB', region: 'Piemonte' },
      { label: 'Vercelli', value: 'VC', region: 'Piemonte' }
    ]
  },
  {
    label: 'Puglia',
    numberOfProvinces: 6,
    options: [
      { label: 'Bari', value: 'BA', region: 'Puglia' },
      { label: 'Barletta-Andria-Trani', value: 'BT', region: 'Puglia' },
      { label: 'Brindisi', value: 'BR', region: 'Puglia' },
      { label: 'Foggia', value: 'FG', region: 'Puglia' },
      { label: 'Lecce', value: 'LE', region: 'Puglia' },
      { label: 'Taranto', value: 'TA', region: 'Puglia' }
    ]
  },
  {
    label: 'Sardegna',
    numberOfProvinces: 8,
    options: [
      { label: 'Cagliari', value: 'CA', region: 'Sardegna' },
      { label: 'Carbonia-Iglesias', value: 'CI', region: 'Sardegna' },
      { label: 'Medio Campidano', value: 'VS', region: 'Sardegna' },
      { label: 'Nuoro', value: 'NU', region: 'Sardegna' },
      { label: 'Ogliastra', value: 'OG', region: 'Sardegna' },
      { label: 'Olbia-Tempio', value: 'OT', region: 'Sardegna' },
      { label: 'Oristano', value: 'OR', region: 'Sardegna' },
      { label: 'Sassari', value: 'SS', region: 'Sardegna' }
    ]
  },
  {
    label: 'Sicilia',
    numberOfProvinces: 9,
    options: [
      { label: 'Agrigento', value: 'AG', region: 'Sicilia' },
      { label: 'Caltanissetta', value: 'CL', region: 'Sicilia' },
      { label: 'Catania', value: 'CT', region: 'Sicilia' },
      { label: 'Enna', value: 'EN', region: 'Sicilia' },
      { label: 'Messina', value: 'ME', region: 'Sicilia' },
      { label: 'Palermo', value: 'PA', region: 'Sicilia' },
      { label: 'Ragusa', value: 'RG', region: 'Sicilia' },
      { label: 'Siracusa', value: 'SR', region: 'Sicilia' },
      { label: 'Trapani', value: 'TP', region: 'Sicilia' }
    ]
  },
  {
    label: 'Toscana',
    numberOfProvinces: 10,
    options: [
      { label: 'Arezzo', value: 'AR', region: 'Toscana' },
      { label: 'Firenze', value: 'FI', region: 'Toscana' },
      { label: 'Grosseto', value: 'GR', region: 'Toscana' },
      { label: 'Livorno', value: 'LI', region: 'Toscana' },
      { label: 'Lucca', value: 'LU', region: 'Toscana' },
      { label: 'Massa-Carrara', value: 'MS', region: 'Toscana' },
      { label: 'Pisa', value: 'PI', region: 'Toscana' },
      { label: 'Pistoia', value: 'PT', region: 'Toscana' },
      { label: 'Prato', value: 'PO', region: 'Toscana' },
      { label: 'Siena', value: 'SI', region: 'Toscana' }
    ]
  },
  {
    label: 'Trentino-Alto Adige',
    numberOfProvinces: 2,
    options: [
      { label: 'Bolzano', value: 'BZ', region: 'Trentino-Alto Adige' },
      { label: 'Trento', value: 'TN', region: 'Trentino-Alto Adige' }
    ]
  },
  {
    label: 'Umbria',
    numberOfProvinces: 2,
    options: [
      { label: 'Perugia', value: 'PG', region: 'Umbria' },
      { label: 'Terni', value: 'TR', region: 'Umbria' }
    ]
  },
  {
    label: 'Valle d\'Aosta',
    numberOfProvinces: 1,
    options: [
      { label: 'Aosta', value: 'AO', region: 'Valle d\'Aosta' }
    ]
  },
  {
    label: 'Veneto',
    numberOfProvinces: 7,
    options: [
      { label: 'Belluno', value: 'BL', region: 'Veneto' },
      { label: 'Padova', value: 'PD', region: 'Veneto' },
      { label: 'Rovigo', value: 'RO', region: 'Veneto' },
      { label: 'Treviso', value: 'TV', region: 'Veneto' },
      { label: 'Venezia', value: 'VE', region: 'Veneto' },
      { label: 'Verona', value: 'VR', region: 'Veneto' },
      { label: 'Vicenza', value: 'VI', region: 'Veneto' }
    ]
  }
]

export function getRegions() { return regions }