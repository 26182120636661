const industriesCategories = [
  'Agricoltura',
  'Banche e assicurazioni',
  'Commercio e grande distribuzione organizzata (escluso moda e lusso)',
  'Consulenza d\'Impresa (analisi, contabilità, comunicazione, legale, studi professionali)',
  'Distribuzione energia, gas e servizi idrici',
  'Edilizia e costruzioni',
  'Gestione Risorse Umane (selezione, formazione, organizzazione, gestione)',
  'ICT',
  'Industria Agroalimentare',
  'Industria della Carta, stampa ed editoria',
  'Industria della gomma e della plastica',
  'Industria elettrica ed elettrotecnica (elettrodomestici, strumentazioni, componentistica)',
  'Industria meccanica, dei macchinari e della lavorazione metalli',
  'Industrie chimiche, farmaceutiche e cosmetiche',
  'Legno e arredo',
  'Logistica e Trasporti',
  'Materiali da costruzione, ceramica e vetro',
  'Moda e lusso (incluso retail)',
  'Pubblica amministrazione',
  'Sanità',
  'Servizi alla persona',
  'Servizi alle imprese (pulizia, sicurezza, giardinaggio, manutenzione)',
  'Telecomunicazioni',
  'Turismo e cultura, ristorazione e hotellerie'
]

const jobCategories = [
  'Gestione e programmazione della produzione (Project management, Supply Chain)',
  'Produzione del bene, erogazione del servizio, punto vendita',
  'Acquisti',
  'Amministrazione, finanza e controllo',
  'Marketing, comunicazione, relazioni esterne',
  'Ricerca e sviluppo',
  'Legale e privacy',
  'Vendite e supporto alla rete commerciale',
  'Servizio clienti e post vendita',
  'HR',
  'Reti e sistemi informativi',
  'Sistema qualità e sicurezza sul lavoro',
  'Segreteria',
  'Ufficio tecnico',
  'Logistica e magazzino',
  'Manutenzione',
  'Recupero crediti'
]

const jobRoles = [
  'Ruoli di coordinamento e manageriali',
  'Ruoli specialistico gestionali (coordinano persone)',
  'Ruoli tecnico specifici (non coordina persone)',
  'Ruoli operativi generici',
  'Laureandi/neolaureati disoccupati per formazione preassuntiva'
]
const seniorities = [
  'Senior (+7 anni)',
  'Middle (4-7 anni)',
  'Junior (1-3 anni)',
  'Nessuna esperienza'
]

export function getIndustriesCategories() {
  return industriesCategories.sort()
}

export function getJobCategories() {
  let jobs = []
  jobCategories.forEach(u => {
    jobs.push({
      category: u,
      id: u
    })
  })
  return jobs
}

export function getUmanaRoles() {
  let roles = []
  jobRoles.forEach(u => {
    roles.push({
      category: u,
      id: u
    })
  })
  return roles
}
export function getUmanaSeniorities() {
  let sen = []
  seniorities.forEach(u => {
    sen.push({
      category: u,
      id: u
    })
  })
  return sen
}